<template>
  <div>
    <h3 class="center">ФОТ на каждую точку (только смены)</h3>
    <div class="heap heapR">
      <div class="heap-block">
        <DateSelector label="Дата нач." :val="date_begin" @change="date_begin = $event" />
      </div>
      <div class="heap-block">
        <DateSelector label="Дата кон." :val="date_end" @change="date_end = $event" />
      </div>
      <div class="heap-block">&nbsp;</div>
      <div class="heap-block">
        <div @click.prevent="tableDataRetrieve" class="high pointer">
          <button class="btn" ref="btn">Обновить</button>
        </div>
      </div>
    </div>

    <div v-if="loading">
      <Loader />
    </div>
    <div v-else>
      <div class="reminder smalltext">работает сортировка по столбцам</div>
      <table class="t_table">
        <tr class="noselect">
          <th
            ref="nickname_sort"
            @click="sort('nickname', report, $refs, oFlag)"
            style="width: 70%"
            class="pointer"
          >
            Точка
          </th>
          <th
            ref="summary_sort"
            @click="sort('summary', report, $refs, oFlag)"
            style="width: 30%"
            class="pointer"
          >
            ЗП суммарно
          </th>
        </tr>

        <template v-if="this.total > 0">
          <tr v-for="(row, i) in report" :key="i">
            <td>{{ row.nickname }}</td>
            <td>{{ row.summary }}</td>
          </tr>
          <tr>
            <td colspan="1"></td>
            <td>
              <b>{{ this.total }}</b>
            </td>
          </tr>
        </template>

        <tr v-else>
          <td colspan="5">нет данных по выбранным параметрам</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import DateSelector from "@/components/DateSelector.vue";
import Loader from "@/components/Loader.vue";
import { dateFormatJS } from "@/components-js/dateFormat";
import { DATE_MAX_INTERVAL } from "@/config/settings";
import { request } from "@/components-js/requestSrv";
import { sortCol } from "@/components-js/sortCol.js";
import { datesCheck } from "@/components-js/datesCheck.js";
import { datesHalfMonth } from "@/components-js/datesHalfMonth.js";
import { lS } from "@/components-js/localStorage";
const loStorage = new lS();

export default {
  components: { DateSelector, Loader },

  data() {
    return {
      loading: false,
      date_begin: null,
      date_end: null,
      DATE_MAX_INTERVAL,

      report: [],
      total: 0,
      oFlag: 1,
    };
  },

  async mounted() {
    // set default values for dates
    const res = datesHalfMonth();
    this.date_begin = dateFormatJS(res.date_begin);
    this.date_end = dateFormatJS(res.date_end);

    let tmp = loStorage.getObjectProp(this.$route.path, "date_begin");
    if (tmp) this.date_begin = tmp;

    tmp = loStorage.getObjectProp(this.$route.path, "date_end");
    if (tmp) this.date_end = tmp;

    this.tableDataRetrieve();
  },

  methods: {
    async request(url, method, data) {
      // imported function
      return request(url, method, data);
    },

    async tableDataRetrieve() {
      this.loading = true;

      const res = datesCheck(this.date_begin, this.date_end, DATE_MAX_INTERVAL);
      this.date_begin = dateFormatJS(res.date_begin);
      this.date_end = dateFormatJS(res.date_end);

      // DB request
      const data = await request(
        "/api/report/summary_per_point",
        "POST",
        {
          date_begin: this.date_begin,
          date_end: this.date_end,
        },
        this.$route.path
      );
      this.loading = false;

      this.total = 0;
      for (let i in data) this.total += parseInt(data[i].summary);

      this.report = data;
    },

    sort(col, arr, refs, oFlag) {
      this.oFlag = sortCol(col, arr, refs, oFlag);
    },
  },
};
</script>
